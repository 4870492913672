import { connectSortBy } from "react-instantsearch-dom"
import Select from "../UI/select"

const _SortBy = ({ items, refine }) => (
  <Select items={items} _onChange={refine} />
)

const SortBy = connectSortBy(_SortBy)

export default SortBy
