import React from "react"
import { connectHits } from "react-instantsearch-dom"
import ProductsList from "../productsList"

const _ProductsHits = ({ hits }) => (
  <ProductsList
    products={hits.map(hit => ({
      ...hit,
      image: hit.images[0].localFile.childImageSharp,
      key: hit.id,
      variations:
        hit.product_variations.length > 0 ? hit.product_variations : null,
    }))}
  />
)

export const ProductsHits = connectHits(_ProductsHits)
