import React from "react"
import { Container } from "react-grid-system"
import Breadcrumbs from "../components/breadcrumbs"
import Search from "../components/search"
import SEO from "../components/seo"
import { H1 } from "../components/UI/typography"

export default ({ location, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Container>
      <SEO title="Поиск по каталогу" />
      <Breadcrumbs crumbs={crumbs} label={"Поиск по каталогу"} />
      <H1>Поиск по каталогу</H1>
      <Search defaultQuery={location.state?.searchQuery ? location.state.searchQuery : ""} />
    </Container>
  )
}
