import algoliasearch from "algoliasearch/lite"
import { Col, Row } from "react-grid-system"
import { InstantSearch } from "react-instantsearch-dom"
import { ProductsHits } from "./hits"
import SearchBox from "./searchBox"
import SortBy from "./sortBy"

const Search = ({ defaultQuery }) => {
  const client = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_API_KEY
  )
  const index = process.env.GATSBY_ALGOLIA_INDEX_NAME
  return (
    <InstantSearch searchClient={client} indexName={index}>
      <Row style={{ marginBottom: 20 }} justify="between">
        <Col>
          <SearchBox defaultRefinement={defaultQuery} />
        </Col>
        <Col>
          <div css={{ display: "flex", justifyContent: "flex-end" }}>
            <SortBy
              defaultRefinement="smalta_products"
              items={[
                { value: "smalta_products", label: "По умолчанию" },
                { value: "price_asc", label: "Цена: по возрастанию" },
                { value: "price_desc", label: "Цена: по убыванию" },
              ]}
            />
          </div>
        </Col>
      </Row>
      <ProductsHits />
    </InstantSearch>
  )
}

export default Search
